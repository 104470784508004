.color-step-button {
    text-transform: uppercase;
    margin-bottom: 64px;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px 6px 6px 8px;
    max-width: 325px;
}
  
.color-step-button:hover {
    text-decoration: underline;
}
  
.color-step-button > svg {
    position: relative;
    top: -5px;
    margin-right: 8px;
}