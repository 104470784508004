.MuiModal-root.MuiDrawer-root.MuiDrawer-modal {
    top: 48px;
}

.MuiModal-root.below-nav {
    top: 48px !important;
}

.MuiPopover-paper.below-nav {
    top: 0px !important;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #696969 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #696969;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
