.confirmation-modal .MuiDialogContent-root {
  line-height: 1.5;
  width: 480px;
  height: 240px;
}

.confirmation-modal .MuiDialogContent-root h6 {
  margin: 0;
  text-align: center;
}

.confirmation-modal .MuiDialogContent-root .MuiBox-root {
  margin-bottom: 32px;
  display: flex;
  gap: 32px;
  margin-top: 32px;
}

.confirmation-modal .MuiDialogActions-root {
  padding: 16px;
}

#popper p {
  cursor: pointer;
}