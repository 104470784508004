.hierarchy-item-hoverable:hover {
    background-color: #eeeeee!important;
}

.hierarchy-context-hoverable:hover {
    color: #eeeeee!important;
}

.hierarchy-context-hoverable-blue:hover {
    color: #1B1AFF!important;
}