@import '../../styles/variables';
@import '../../styles/helpers/theme-color';

.outlined-input {
    margin-top: $spacing-s;
    cursor: pointer;
    border-radius: 4px;
    height: 1.4375em;
    padding: 16.5px 14px;
    border: solid 1px rgba(0,0,0,0.12);
    overflow: hidden;
    line-height: 1.4375em;
    position: relative;

    &.error {
        border: solid 1px theme-color(error);
    }

    &:hover {
        border-color: rgba(0,0,0,0.87);
    }

    &.textarea-option {
        height: 69px;

        .input-inner {
            overflow: auto;
            height: 100%;
        }
    }

    &.input-option .input-inner {
        white-space: nowrap;
        overflow: hidden;
        position: absolute;
        width: calc(100% - 30px);
    }
}

.text-editor-error {
    color: theme-color(error);
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin: 3px 14px 0px;
}

.tox {
    .tox-pop__dialog .tox-tbtn svg,
    .tox-pop__dialog .tox-tbtn:hover svg {
        fill: theme-color(secondary);
    }

    .tox-toolbar__group:nth-last-child(2) {
        flex-grow: 1;
    }
}

.tox-tinymce-aux {
    z-index: 99999 !important;
}
