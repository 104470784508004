@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

html, body, #root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

html {
  box-sizing: border-box;
}

body {
  margin: 0px;
  font-family: 'Roboto';
}

/* Typography */
.headline4 {
  font-family: Roboto;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;
}

.headline5 {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.18000000715255737px;
  text-align: left;
}

.headline6 {
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}

.body1 {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
}

.body2 {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
}

.button {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: left;
}

.caption {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
}

.subtitle1 {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: center;
}

.subtitle2 {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
}

.button-typography {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
}

a {
  color: #6200EE;
}

.content-color {
  color: #292929;
}

.drawer {
  padding: 0 25px
}

.mt1 {
  margin-top: 8px;
}

.m2 {
  margin: 16px;
}

.mt2 {
  margin-top: 16px;
}

.mt8 {
  margin-top: 64px;
}

.mb2 {
  margin-bottom: 16px;
}
.mb4 {
  margin-bottom: 32px;
}

.mx2 {
  margin-left: 16px;
  margin-right: 16px;
}

.hideScrollbars {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.hideScrollbars::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}
