.template-select-overlay {
    background-color: rgba(0, 0, 0, .7);
    width: 100%;
    height: 100%;
    display: flex;
    opacity: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: white;

    &:hover,
    &:focus-within {
        opacity: 1;
    }
}
