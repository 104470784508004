.display-no-image {
    text-align: center;
    background-color: #e8e8e8;
    width: 100%;
    max-width: 420px;
}

.MuiImageListItemBar-title {
    font-family: Roboto;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 16px !important;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
}

.MuiImageListItemBar-subtitle {
    font-family: Roboto;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 24px !important;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
}

.MuiImageListItemBar-title {
    color: black;
}

.MuiImageListItemBar-subtitle {
    color: black;
}