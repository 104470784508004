.site-wizard {
    height: 100%;

    .wizard-step-container {
        height: calc(100% - 69px);
        overflow: auto;
        box-sizing: border-box;
        padding-left: 32px;
        padding-right: 32px;
        display: flex;
        flex-direction: column;

        &.noactions {
            height: 100%;
        }

        > button {
            margin: 0 auto;
            width: initial;
        }
    }

    .wizard-body {
        height: calc(100% - 48px - 65px);
        display: flex;
    }

    .wizard-col-1 {
        height: 100%;
        width: 100%;
        overflow: auto;
    }

    .wizard-col-2 {
        height: 100%;
        overflow: auto;
        display: none;
    }

    .wizard-step-body-container {
        height: calc(100% - 136px);
    }

    .wizard-step-header-container {
        max-width: 424px;
        height: 72px;

        > h1 {
            margin: 0px;
        }

        > p {
            margin: 0px;
            margin-top: 8px;
        }
    }

    .wizard-step-section {
        max-width: 424px;

        > .MuiAlert-root {
            padding: 0;
        }
    }

    .wizard-step-header-container,
    .wizard-step-section {
        margin-bottom: 32px;
    }

    .MuiStepper-root {
        * .MuiSvgIcon-root {
            cursor: pointer;
        }

        &.template-step * .MuiSvgIcon-root {
            cursor: initial;
        }
    }

    @media only screen and (min-width: 900px) {
        .wizard-col-1.has-preview {
        width: 60%;
        }

        .wizard-col-2 {
        width: 40%;
        display: block;
        }

        .wizard-col-1.review,
        .wizard-col-2.review{
            width: 50%;
        }

        .wizard-step-container {
            padding-top: 64px;
            display: inherit;
        }

        .wizard-step-header-container,
        .wizard-step-section {
            margin-bottom: 64px;
        }
    }
}