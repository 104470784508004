#slider-input-control * input {
    text-align: right;
    padding-top: 5px;
}

#slider-input-control * input::-webkit-outer-spin-button {
    display: none;
}
#slider-input-control * input::-webkit-inner-spin-button {
    display: none;
}
#slider-input-control > .MuiFilledInput-root {
    width: 72px;
}

#slider-input-control {
    margin-top: 0px;
    margin-left: 24px;
}

.button-edit-button > svg {
    margin-right: 8px;
    height: 18px;
    width: 18px;
  }