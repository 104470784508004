.cta-background-step-radios {
    margin-top: 1rem;
    line-height: 1.5rem;
}

.image-scroll-speed-container {
    margin-top: 2rem;
}

#cta-background-step-container {
    height: 8rem;
}
