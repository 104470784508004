.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}
  
.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.font-preview {
    font-size: 22px;
    line-height: 36px;
}