$theme-colors: (
  primary: (
    main: #6200EE,
    light: #BB86FC,
  ),
  secondary: (
    main: #1B1AFF,
  ),
  error: (
    main: #CF1F2E,
  ),
);

$spacing-xs: 4px;
$spacing-s: 8px;
$spacing-m: 16px;
$spacing-l: 32px;
$spacing-xl: 64px;
