.container-grid {
    display: grid;
    grid-gap: 1em;
    grid: auto-flow min-content / minmax(auto, 359px);
    margin-top: 1em;
}

@media screen and (min-width: 550px) {
    .container-grid {
        grid: auto-flow min-content / repeat(2,minmax(auto, 171px));
    }
}

@media screen and (min-width: 1250px) {
    .container-grid {
        grid: auto-flow min-content / repeat(3,minmax(auto, 171px));
    }
}

.step-instructions {
    width: 336px;
    font-size: 16px;
}