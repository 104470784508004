.MuiAutocomplete-option, .MuiMenuItem-root {
    padding-left: 16px;
    display: flex;
    align-items: center;
    height: 60px;
    font-weight: 500;
    background-color: white;
}

.MuiAutocomplete-option[aria-selected="true"],
.MuiMenuItem-root[aria-selected="true"] {
    background-color: white!important;
}

.MuiAutocomplete-option.Mui-focused,
.MuiMenuItem-root:hover {
    color: white;
    background-color: #1B1AFF!important;
}

.MuiAutocomplete-option.Mui-focused > svg {
    color: white;
}

.default-pill {
    margin-left: 16px;
    font-size: 12px;
    font-weight: 400;
    padding: 0px 8px;
    border-radius: 10px;
    color: black!important;
    background-color: #E0E0E0;
}