.site-wizard-actions {
    border-top: thin solid rgba(0, 0, 0, 0.12);
    bottom: 0;
    position: absolute;
    width: 100%;

    @media only screen and (min-width: 900px) {
        width: 60%;

        &.review {
            width: 50%;
        }
    }
}
